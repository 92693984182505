.explanation-text {
  font-weight: 400;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;

  /* Inside auto layout */
  flex: none;
  order: 4;
  flex-grow: 0;
  margin-bottom: 20px;
}

.bottom-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 100%;
  height: 30px;
  flex: none;
  order: 5;
  align-self: stretch;
  flex-grow: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #ffffff;
}

.password-container {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 10px;
  width: 100%;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.form-group {
  display: flex;
  width: inherit;
  gap: 10px;
}

.form-group-label {
  flex: 1;
  top: 5px;
  position: relative;
  text-align: left;
  width: 10%;
}

.form-group-input {
  flex: 4;
}

.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

.iconButton {
  position: absolute;
  padding: 5px;
  top: 0px;
  bottom: 0px;
  margin: 0 auto;
  right: 5px;
  display: flex;
  align-items: center;
}

.iconButton:hover {
  color: #535050;
  cursor: pointer;
}

.drNucU {
  display: block !important;
}

.hLxvPP {
  margin-bottom: 4px;
}

.pass-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}