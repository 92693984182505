.dialog-container {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(41, 41, 41, 0.95);
    top: 12.5vh;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    width: 75vw;
    height: 75vh;
    border: 1px solid rgba(41, 41, 41, 0.95);
    z-index: 2;
    border-radius: 15px;
}
  
.code-textbox {
    max-width: 50%;
    margin: 0;
}
