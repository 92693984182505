.subheading {
    height: 19px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin-bottom: 17px;

    color: #000000;


    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    /* identical to box height */
    display: flex;
    align-items: center;

    color: #000000;


    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
