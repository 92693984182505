.enc-dec-button {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;

    width: 100%;
    height: 30px;
    flex: none;
    order: 5;
    align-self: stretch;
    flex-grow: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    background-color: #7F0000;
    /* identical to box height */

    color: #ffffff;
}
